<template>
  <div class="tooltip">
    <img src="@/assets/img/ico_question.svg" alt />
    <div class="top">
      <span>
        <slot> </slot>
      </span>
      <i></i>
    </div>
  </div>
</template>

<script>
export default {
  name : "ToolTip"
}
</script>
