<template>
  <span class="tooltip"><span class="underline">Hizmet Sağlayıcı</span>

    <div class="top">
      <span>
        <slot>Kanun çerçevesinde, arama, mesaj, e-posta vb. araçları kullanarak ticari iletişim sağlayan gerçek ve tüzel kişiler Hizmet Sağlayıcı olarak adlandırılmaktadır.</slot>
      </span>
      <i></i>
    </div>
  </span>

</template>

<script>
export default {
  name : "ToolTipHs"
}
</script>

<style scoped>


</style>
