<template>
  <div :class="{ notvalid: problem }">
    <label>{{ title }}</label>

    <select
      name="#"
      v-model="selected_il"
      @change="updateAddress($event)"
      :class="{ error: selected_il.id < 1 }"
    >
      <!-- <option :value="0" selected>İl seçiniz</option> -->
      <option v-for="item in il_listesi" :key="item.id" :value="item">{{ item.name }}</option>
      <div class="hint">Bu alan boş bırakılamaz</div>
    </select>

    <select
      name="#"
      v-model="selected_ilce"
      @change="updateAddress($event)"
      :class="{ error: selected_ilce.id < 1 }"
    >
      <!-- <option :value="0" selected>İlçe seçiniz</option> -->
      <option v-for="item in ilce_listesi" :key="item.id" :value="item">{{ item.name }}</option>
      <div class="hint">Bu alan boş bırakılamaz</div>
    </select>

    <select
      name="#"
      v-model="selected_mahalle"
      @change="updateAddress($event)"
      :class="{ error: selected_mahalle.id < 1 }"
    >
      <!-- <option :value="0" selected>Mahalle seçiniz</option> -->
      <option v-for="item in mahalle_listesi" :key="item.id" :value="item">{{ item.name }}</option>
      <div class="hint">Bu alan boş bırakılamaz</div>
    </select>

    <textarea
      rows="4"
      placeholder="Adres"
      autocomplete="street-address"
      v-model="explicitAddress"
      @input="updateAddress($event)"
    ></textarea>

    <div class="hint">{{ problem }}</div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "UserAddress",

  props: {
    title: {
      type: String,
      required: true
    },
    problem: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      il_listesi: [],
      selected_il: { id: "", code: "", name: "" },
      ilce_listesi: [],
      selected_ilce: { id: "", code: "", name: "" },
      mahalle_listesi: [],
      selected_mahalle: { id: "", code: "", name: "" },

      explicitAddress: ""
    };
  },

  computed: {
    il_ilce_mahalle_all_selected() {
      if (
        parseInt(this.selected_il.id) > 0 &&
        parseInt(this.selected_ilce.id) > 0 &&
        parseInt(this.selected_mahalle.id) > 0
      )
        return true;
      return false;
    }
  },

  mounted() {
    this.Get_Il_Listesi();

    this.$root.$on("SET_MERSIS_ADRESS", this.SET_IL_ILCE_ADDRESS);

    if (this.$env.MOCK) {
      // this.MOCK();
    }
  },

  beforeDestroy() {
    this.$root.$off("SET_MERSIS_ADRESS", this.SET_IL_ILCE_ADDRESS);
  },

  watch: {
    selected_il(n) {
      this.$env.CONSOLE.log("SECILEN IL:", JSON.parse(JSON.stringify(n)));
      this.selected_ilce = { id: "0", code: "0", name: "İlçe seçiniz" };
      this.selected_mahalle = { id: "0", code: "0", name: "Mahalle seçiniz" };
      this.ilce_listesi = [{ id: "0", code: "0", name: "İlçe seçiniz" }];
      this.mahalle_listesi = [{ id: "0", code: "0", name: "Mahalle seçiniz" }];

      this.updateAddress();

      if (n.id == "0") return;

      this.get_ilce_listesi(n.id)
        .then(res => {
          if (res.ok) {
            this.ilce_listesi = res.payload;
            this.ilce_listesi.unshift({
              id: "0",
              code: "0",
              name: "İlçe seçiniz"
            });
            this.updateAddress();
          }
        })
        .catch(err => {
          if (typeof err.response == "undefined") {
            this.ConnectionError();
          }
        });
    },

    selected_ilce(n) {
      this.$env.CONSOLE.log("SECILEN ILÇE:", JSON.parse(JSON.stringify(n)));
      this.selected_mahalle = { id: "0", code: "0", name: "Mahalle seçiniz" };
      this.mahalle_listesi = [{ id: "0", code: "0", name: "Mahalle seçiniz" }];

      this.updateAddress();

      if (n.id == "0") return;

      this.get_mahalle_listesi(n.id)
        .then(res => {
          if (res.ok) {
            this.mahalle_listesi = res.payload;
            this.mahalle_listesi.unshift({
              id: "0",
              code: "0",
              name: "Mahalle seçiniz"
            });
            this.updateAddress();
          }
        })
        .catch(err => {
          if (typeof err.response == "undefined") {
            this.ConnectionError();
          }
        });
    }
  },

  methods: {
    ...mapActions("applicant", ["get_il_listesi", "get_ilce_listesi", "get_mahalle_listesi"]),

    async SET_IL_ILCE_ADDRESS(address) {
      this.$env.CONSOLE.log("SETTING ADDRESS TO MERSIS RETURNED ADDRESS:", address);
      this.explicitAddress = address.explicitAddress;
      this.updateAddress();
      this.get_il_listesi().then(res => {
        if (res.ok) {
          this.il_listesi = res.payload;
          let gelen_il = this.il_listesi.find(
            e => e.name.toUpperCase() == address.city.name.toUpperCase()
          );
          this.selected_il = gelen_il;
        }
        this.get_ilce_listesi(this.selected_il.id).then(res => {
          if (res.ok) {
            this.ilce_listesi = res.payload;
            let gelen_ilce = this.ilce_listesi.find(
              e => e.name.toUpperCase() == address.town.name.toUpperCase()
            );
            this.selected_ilce = gelen_ilce;
          }
          this.get_mahalle_listesi(this.selected_ilce.id).then(res => {
            if (res.ok) {
              this.mahalle_listesi = res.payload;
              this.mahalle_listesi.unshift({
                id: "0",
                code: "0",
                name: "Mahalle seçiniz"
              });
              this.selected_mahalle = { id: "0", code: "0", name: "Mahalle seçiniz" };
              this.updateAddress();
            }
          });
        });
      });
    },

    async Get_Il_Listesi() {
      this.il_listesi = [{ id: "0", name: "İl seçiniz" }];
      this.ilce_listesi = [{ id: "0", name: "İlçe seçiniz" }];
      this.mahalle_listesi = [{ id: "0", name: "Mahalle seçiniz" }];

      await this.get_il_listesi()
        .then(res => {
          if (res.ok) {
            this.il_listesi = res.payload;
            this.il_listesi.unshift({ id: "0", name: "İl seçiniz" });
            this.selected_il = this.il_listesi[0];
          }
        })
        .catch(err => {
          if (typeof err.response == "undefined") {
            this.ConnectionError();
          } else {
            this.Alert("İl listesi yüklenemedi. Lütfen daha sonra tekrar deneyiniz.");
          }
        });
    },

    updateAddress(event) {
      this.$emit("input", {
        city: this.selected_il,
        town: this.selected_ilce,
        district: this.selected_mahalle,
        explicitAddress: this.explicitAddress
      });
    },

    MOCK() {
      this.explicitAddress = "Kutluk Sokak. No:10/1";

      var addr = {
        mahalle: {
          id: 724,
          code: "00724",
          name: "YENİKÖY",
          town_id: 30
        },
        ilce: {
          id: 30,
          code: "030",
          name: "SARIYER",
          city_id: 34
        },
        il: {
          id: 34,
          code: "34",
          name: "İSTANBUL"
        }
      };

      this.get_il_listesi().then(res => {
        if (res.ok) {
          this.il_listesi = res.payload;
          this.selected_il = addr.il;
        }
        this.get_ilce_listesi(this.selected_il.id).then(res => {
          if (res.ok) {
            this.ilce_listesi = res.payload;
            this.selected_ilce = addr.ilce;
          }
          this.get_mahalle_listesi(this.selected_ilce.id).then(res => {
            if (res.ok) {
              this.mahalle_listesi = res.payload;
              this.selected_mahalle = addr.mahalle;
              this.updateAddress();
            }
          });
        });
      });
    }
  }
};
</script>
