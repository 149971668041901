<template>
  <div class="mainimgcontainer">
    <img :src="images[0]" class="resim" :class="{active:currentNumber==0}" />
    <img :src="images[1]" class="resim" :class="{active:currentNumber==1}" />
    <img :src="images[2]" class="resim" :class="{active:currentNumber==2}" />
  </div>
</template>


<script>
const imagesContext = require.context(
  "@/assets/img/hero/main/",
  false,
  /\.png$/
);

export default {
  name: "MainImage",

  props: {
    imageCount: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      currentNumber: 0,
      timer: null,
      images: []
    };
  },

  mounted() {
    this.setImages();
    this.startRotation();
  },

  methods: {
    startRotation() {
      this.timer = setInterval(this.next, 3000);
    },

    next() {
      if (this.currentNumber == this.imageCount - 1) {
        this.currentNumber = 0;
      } else {
        this.currentNumber += 1;
      }
    },

    setImages() {
      for (let i = 0; i < this.imageCount; i++) {
        this.images.push(imagesContext(`./ileti_app_${i}.png`));
      }
    }
  }
};
</script>


<style scoped>
.resim {
  position: absolute;
  opacity: 0;
  transition: all 1.6s ease-out;
  padding: 0;
  margin: 0;
  top: 0;
  bottom: 0;
}
.active {
  opacity: 1;
}

.mainimgcontainer {
  padding: 0;
  margin: 30px 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

/*  on big screens ********************************************************************************************/
/* @media only screen and (min-height: 1000px) {
} */
/*  on phones ********************************************************************************************/
@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
  .mainimgcontainer {
  width: 100%;
}
}
/*  on tablets portrait ********************************************************************************************/
@media (orientation: portrait) and (min-width: 501px) {


}
</style>
