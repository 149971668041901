// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import {
  env
} from "@/misc/IYSEnvironment";
// import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import VueKeyCloak from "./misc/myKeycloak";
import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";

import Vuex from "vuex";
Vue.use(Vuex);

import {
  makeServer
} from "./server";

if (process.env.VUE_APP_MOCK_SERVER) {
  makeServer();
}

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.use(Loading, {
  color: "#4DA0DF",
  // loader: "dots"
  loader: "spinner"
});

import IYSEnvironment from "./misc/IYSEnvironment";
Vue.use(IYSEnvironment);

import IYSMain from "./misc/IYSMain";
Vue.use(IYSMain);

import IYSComponents from "./misc/IYSComponents";
Vue.use(IYSComponents);

const VueInputMask = require("vue-inputmask").default;
Vue.use(VueInputMask);

import VueRellax from "vue-rellax";
Vue.use(VueRellax);

// import MainImage from "./components/sayfalar/MainImage";
// Vue.use(MainImage);

import VueTinySlider from "vue-tiny-slider";
import "tiny-slider/dist/tiny-slider.css";
Vue.component("tiny-slider", VueTinySlider);

// import VueMatomo from "vue-matomo";
// import matomoConfig from "../matomoConfig";
// Vue.use(VueMatomo, matomoConfig(router));

Vue.config.productionTip = false;
// Vue.config.devtools = true
// Vue.config.performance = true

import SocialSharing from "vue-social-sharing";
Vue.use(SocialSharing);

import VueImg from 'v-img';
const vueImgConfig = {
  // Use `alt` attribute as gallery slide title
  altAsTitle: false,
  // Display 'download' button near 'close' that opens source image in new tab
  sourceButton: false,
  // Event listener to open gallery will be applied to <img> element
  openOn: 'click',
  // Show thumbnails for all groups with more than 1 image
  thumbnails: false,
}
Vue.use(VueImg, vueImgConfig);

// Vue.config.errorHandler = function(err, vm, info) { this.$env.CONSOLE.log("Error"); vm.Alert("Bir hata oluştu.") }
// Vue.config.warnHandler = function(msg, vm, info) { vm.Alert("Warning")}

function startVue() {
  if (process.env.NODE_ENV !== "production") {
    window.app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  } else {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  }
}

import initOptions from "../keycloakConfig"
export default new Promise(resolve => {

  let LoggedIn = window.location.hash.startsWith("#state=");

  if (LoggedIn) {
    Vue.use(VueKeyCloak, {
      config: {
        ...initOptions,
        onLoad: "login-required",
        // promiseType: "native"
      },
      onReady: async keycloak => {
        // await tokenInterceptor(keycloak);
        env.CONSOLE.warn("Keycloak on onReady:", keycloak);
        env.CONSOLE.warn("TOKEN:", keycloak.token);
        startVue();
        resolve();
      },
      onInitError: error => {
        console.error(error);
      }
    });

  } else {
    startVue();
    resolve();
  }

})
