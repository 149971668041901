<template>
  <div class="hizmet-sozlesmesi-all">
    <hizmet-sozlesmesi></hizmet-sozlesmesi>

    <div class="check">
      <label class="labelcontainer">
        <input
          type="checkbox"
          v-model = "hizmet_sozlesmesini_okudum"
          @change="$emit('change', hizmet_sozlesmesini_okudum)"
        />
        <span class="checkmark"></span>
      </label>
      <div class="blue">
        <!-- <router-link to="/hizmet-saglayici/basvuru/form/hizmet-sozlesmesi">Hizmet Sözleşmesi</router-link>'ni okudum ve kabul ediyorum. -->
        <!-- <a href @click.prevent="SET_MODAL('ModalHizmetSozlesmesi')">İYS Hizmet Sağlayıcı Temel Hizmetler Kullanım Taahhütnamesi</a>'ni okudum
        ve kabul ediyorum. -->
        Temel Hizmetler Kullanım Taahhütnamesi'ni okudum ve onaylıyorum.
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "HizmetSozlesmesiKabul",
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hizmet_sozlesmesini_okudum: false
    };
  },
  watch: {
    checked: {
      immediate: true,
      handler(n) {
        this.hizmet_sozlesmesini_okudum = n;
        this.$emit('change', n)
      }
    }
  }
  // methods: {
  //   ...mapMutations("app", ["SET_MODAL"])
  // }
};
</script>
